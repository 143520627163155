.btn {
  border-radius: 50px;
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-right: 15px;
  transition: all 0.4s;
  font-size: 14px;
  font-weight: $font-bold;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $color-accent;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500;
    color: #444444;
  }

  svg {
    height: 18px;
    width: 18px;
    transition: all 0.3s;
    fill: #444444;
    margin-right: 5px;
  }

  i{
    font-size: 140%;
    position: relative;
    top: 2px;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus,
  &:active,
  &:active:focus {

    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.icon {
    padding: 10px 15px;

    &:hover {

      p {
        color: #646777;
      }

      svg {
        fill: #646777;
      }
    }

    &.icon--right {

      svg {
        margin: 2px 0 0 5px;
      }
    }
  }

  &.btn-sm {
    padding: 5px 25px;
    font-size: 14px;
  }

  &.btn-lg {
    padding: 12px 25px;
    font-size: 14px;
  }

  &.btn-secondary {
    background-color: $color-blue;
    border-color: $color-blue;

    &:before {
      background-color: $color-blue-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-blue-hover;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: $color-black;
      border-color: $color-black;
    }
  }

  &.btn-outline-secondary {
    border-color: $color-blue;
    border-width: 2px;
    color: $color-blue;

    p {
      color: $color-blue;
    }

    svg {
      fill: $color-blue;
    }

    &:before {
      background-color: $color-blue;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $color-white;

      p {
        color: $color-white;
      }

      svg {
        fill: $color-white;
      }
    }
  }

  &.btn-primary {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;

    &:before {
      background-color: $color-gray;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-gray;
    }
  }

  &.btn-outline-primary {
    color: $color-black;
    border-color: $color-black;
    border-width: 2px;

    p {
      color: $color-black;
    }

    svg {
      fill: $color-black;
    }

    &:before {
      background-color: $color-black;
    }
  }

  &.btn-success {
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-black;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-outline-success {
    color: $color-accent;
    border-color: $color-accent;
    border-width: 2px;

    p {
      color: $color-accent;
    }

    svg {
      fill: $color-accent;
    }

    &:before {
      background-color: $color-accent;
    }
  }

  &.btn-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: $color-black;

    &:before {
      background-color: $color-yellow-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-yellow-hover;
    }
  }

  &.btn-outline-warning {
    color: $color-yellow;
    border-color: $color-yellow;
    border-width: 2px;

    p {
      color: $color-yellow;
    }

    svg {
      fill: $color-yellow;
    }

    &:before {
      background-color: $color-yellow;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border-color: $color-red;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-red-hover;
    }
  }

  &.btn-outline-danger {
    color: $color-red;
    border-color: $color-red;
    border-width: 2px;

    p {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }

    &:before {
      background-color: $color-red;
    }
  }

  &,
  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success,
  &.btn-outline-secondary,
  &.btn-secondary,
  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success,
  &.icon,
  &.icon.btn-secondary {

    &.disabled {
      background-color: #f2f4f7;
      border-color: #f2f4f7;
      color: #bbbbbb;
      pointer-events: none;

      p {
        color: #bbbbbb;
      }

      svg {
        fill: #bbbbbb;
      }

    }
  }

  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success {
    p {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }

  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success {

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: #ffffff;
      background: transparent;
    }
  }

  &.btn-primary,
  &.btn-danger,
  &.btn-warning,
  &.btn-success,
  &.btn-outline-primary,
  &.btn-outline-danger,
  &.btn-outline-warning,
  &.btn-outline-success {

    &:hover,
    &:focus,
    &:active,
    &:active:focus {

      p {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }

  }

  &.expand {

    svg {
      width: 0;
      transition: all 0.3s;
    }

    &.expand--load {

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      svg {
        width: 14px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}

.btn-payment {
  border: 1px $color-black solid;
}

.btn-toolbar {
  margin-top: 15px;
  margin-bottom: 10px;

  &>* {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &.btn-toolbar--center {
    width: 100%;
    justify-content: center;

    &>* {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  border-radius: 5px;
  margin-bottom: -10px;

  .btn {
    margin-right: 0;
    padding: 10px 15px;
  }

  &.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.btn-group--icons {

    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

button:focus,
button:active {
  outline: none;
}

.open>.dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:focus,
.btn-default.focus,
.btn-default:focus {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.btn-cancel {
  background-color: $color-dark-gray;
  border-color: $color-dark-gray;
  color: $color-white;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    border-color: $color-black;
    background-color: $color-black;
    color: $color-accent;
  }
}

.control-btn {
  width: 30px;
  line-height: 30px;
  font-size: 20px;
  text-align: center;
  margin: 0;
  padding: 0;

  i{
    font-size: 95%;
  }

  &::before {
    background-color: $color-black;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &.active {
    border-color: $color-black;
    background-color: $color-black;
    color: $color-white;
  }
}


.btn-upload__media {
  // color: $color-medium-gray;
  // //background-color: $color-medium-gray;
  // border-color: $color-medium-gray;
  // margin-top: $elementXMargin;
  // position: fixed;
  // bottom: 15px;
  // right: 30px;
  // z-index: 100;
  // padding: 0;
  // height: 40px;
  // width: 40px;
}

.btn-pick_me {
  box-shadow: $color-black 2px 2px 0;
  border: 2px solid $color-black;
  margin-bottom: 0;
  padding: 8px 15px;
  position: relative;
  z-index: 2;
}

.btn-delete {
  height: 32px;
  width: 32px;
  //background-color: rgba( 255, 255, 255, 0.4);
  background-color: $color-white;
  color: $color-red;
  border: 1px $color-red solid;
  font-size: 20px;
  border: 0;
  border-radius: 100%;
  transition: all 0.3s;

  &:hover {
    background-color: $color-black;
    color: $color-white;
  }
}

.btn-back {

  &.btn {
    color: $color-black;
    background-color: $color-white;
    border-color: $color-white;

    &:hover {
      color: $color-white;
      cursor: pointer;
    }
  }

}