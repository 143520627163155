.manage__quo {
  padding-top: 30px;

  .quos__container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

  }

  .card {
    width: 31%;
    padding-bottom: 0;
  }

  @media screen and (max-width: 992px) {
    .card {
      width: 45%;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: $siteTopMobileMargin;

    .card {
      width: 100%;
    }
  }
}

.quo__body {
  padding-right: 40px;
  padding-left: 20px;

  :hover {
    cursor: pointer;
  }

  .quo__status-name {
    a {
      color: $color-black;

      &:hover {
        color: $color-blue;
      }
    }
  }

  .quo__status-avatar {
    margin-right: 5px;
    display: inline-block;

    img {
      width: 23px;
      height: 23px;
      border-radius: 100%;
    }
  }

  .quo__start {
    display: flex;
    align-items: center;
  }

  .quo__inbox {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .quo__more {
    height: 14px;
    position: absolute;
    right: 15px;
    top: calc(50% - 7px);
  }

  .quo__body__link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .quo__chat-click {
    padding: 0;

    &:hover {
      color: $color-white;
    }

    &:before {
      background-color: $color-black;
    }
  }

  .quo__chat-click,
  .quo__btn,
  .control-btn,
  .quo__status-name {
    position: relative;
    z-index: 2;
  }

  .center {
    text-align: center;
    margin: auto;
  }

  .ellipsis {
    display: inline;
  }

  .readmore-btn {
    background: transparent;
    border: 0;
    color: $color-black;
    font-size: 11px;
    text-decoration: underline;


    &:hover {
      color: $color-blue;
    }
  }

  &.quo__body-pending {
    background-color: $color-blue;
    color: $color-white;

    .quo__chat-click {
      color: $color-white;

      &:hover {
        color: $color-white;
      }

      &:before {
        background-color: $color-black;
      }
    }

    .bold-text {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .quo__status-name {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .readmore-btn {
      color: $color-white;

      &:hover {
        color: $color-accent;
      }
    }

    .quo__btn {
      padding: 6px 10px;
    }
  }

  &.quo__body-accepted,
  &.quo__body-delivered {
    background-color: $color-green;
    color: $color-white;

    .quo__chat-click {
      color: $color-white;

      &:hover {
        color: $color-black;
      }

      &:before {
        background-color: $color-accent;
      }
    }

    .quo__btn__container {
      width: 60%;
    }

    .bold-text {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .quo__status-name {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .quo__btns {
      justify-content: center;
    }

    .quo__btn {
      &:hover {
        background-color: $color-accent;
        color: $color-black;
      }
    }

    .readmore-btn {
      color: $color-white;

      &:hover {
        color: $color-accent;
      }
    }

  }

  &.quo__body-countering {
    background-color: $color-accent;
    color: $color-black;

    ::selection {
      background-color: $color-blue;
    }

    .quo__chat-click {
      &:hover {
        color: $color-white;
      }

      &:before {
        background-color: $color-black;
      }
    }

    .bold-text {
      a {
        color: $color-black;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .quo__status-name {
      a {
        color: $color-black;

        &:hover {
          color: $color-blue;
        }
      }
    }

    .quo__btns {
      margin-left: auto;
      margin-right: auto;
    }

    .quo__btn {
      :hover {
        background-color: $color-accent;
      }
    }
  }

  &.quo__body-rejected {
    background-color: $color-red;
    color: $color-white;

    .quo__chat-click {
      color: $color-white;

      &:hover {
        color: $color-white;
      }

      &:before {
        background-color: $color-black;
      }
    }

    .bold-text {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .quo__status-name {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .readmore-btn {
      color: $color-white;

      &:hover {
        color: $color-accent;
      }
    }
  }

  &.quo__body-timeout {
    background-color: $color-light-gray;

    .quo__chat-click {
      &:hover {
        color: $color-white;
      }

      &:before {
        background-color: $color-black;
      }
    }
  }

  &.quo__body-searching {

    background-color: #4F88C0;
    color: $color-white;

    .quo__chat-click {
      color: $color-white;

      &:hover {
        color: $color-white;
      }

      &:before {
        background-color: $color-black;
      }
    }

    .quo__from {
      display: none;
    }

    .bold-text {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .quo__status-name {
      a {
        color: $color-white;

        &:hover {
          color: $color-accent;
        }
      }
    }

    .readmore-btn {
      color: $color-white;

      &:hover {
        color: $color-accent;
      }
    }

    .quo__btns {
      .quo__btn__container {
        width: 45%;
        text-align: center;
      }
    }

    .quo__btn {
      display: block;
      line-height: 1;
    }
  }

  &.quo__body-inviting {
    background-color: #A8C6E3;

    .quo__from {
      display: none;
    }
  }
}

.quo__body-accepted {
  .quo__btn.quo__btn-submit {
    width: 50%;
  }
}

.quo__counter-msg {
  background-color: $color-white;
  color: $color-black;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
}


.quo__info {
  display: flex;
  margin-top: $elementMargin;
  margin-bottom: $elementMargin;

  .bold-text {
    margin-bottom: 15px;
    word-break: break-all;

    a {
      color: $color-black;
    }
  }

  .quo__delivery {
    margin-bottom: $elementMargin;
  }

  .quo__media,
  .quo__delivery {
    font-size: 12px;
  }

  .quo__from {
    margin-bottom: 15px;
  }

  .quo__status-avatar {
    margin-right: 5px;
    display: inline-block;

    img {
      width: 23px;
      height: 23px;
      border-radius: 100%;
    }
  }

}

.quo__badge {
  width: 100px;
  text-align: center;
  margin-right: 15px;

  .quo__badge-pic {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .quo__badge-deal {
    background: url(#{$assetPath}/badge-deal.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl10 {
    background: url(#{$assetPath}/badge-bl10.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl20 {
    background: url(#{$assetPath}/badge-bl20.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl50 {
    background: url(#{$assetPath}/badge-bl50.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl100 {
    background: url(#{$assetPath}/badge-bl100.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl200 {
    background: url(#{$assetPath}/badge-bl200.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl500 {
    background: url(#{$assetPath}/badge-bl500.svg) center no-repeat;
    background-size: contain;
  }

  .quo__badge-bl1000 {
    background: url(#{$assetPath}/badge-bl1000.svg) center no-repeat;
    background-size: contain;
  }

  .quo__price {
    font-weight: $font-xbold;
  }
}

.quo__status {
  font-size: 11px;
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: $color-white;
  color: $color-black;

  .quo__btn {
    margin: 0 5px;
  }

  .quo__status-notice {
    display: inline;
  }

  .quo__status-name {
    font-weight: $font-bold;
  }

  .quo__status-count {
    font-weight: $font-bold;
  }

  .control-btn {
    margin-left: 5px;
    border-width: 1px;

    &:before {
      background-color: $color-black;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: $color-black;
      color: $color-white;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;

    .quo__btn {
      font-size: 13px;
      margin: auto;
      display: flex;
    }
  }
}

.quo__btns {
  justify-content: space-evenly;
  display: flex;
  margin-top: $elementMargin;
  line-height: 2;
  word-wrap: nowrap;

  // height: 46px;
  .quo__btn__container {
    width: 31%;

    .quo__btn {
      width: 100%;
    }
  }
}

.quo__btn {
  padding: 5px 15px;
  border-radius: 50px;
  background-color: $color-black;
  color: $color-white;
  border: 0;
  // display: flex;
  margin: auto;
  transition: all .3s;

  &:hover {
    background-color: $color-blue;
    color: $color-white;
  }

  &.quo__btn-history {
    display: flex;
    margin-left: auto;
  }

  &.quo__btn-submit {
    width: 30%;
    text-align: center;
    margin: auto;
  }

  &.quo__btn-reject,
  &.quo__btn-counter,
  &.quo__btn-accept,
  &.quo__btn-delivered {

    &:hover {
      background-color: $color-black;
    }

  }

  &.quo__btn-searching {
    &:hover {
      background-color: $color-accent;
      color: $color-black;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
}


.quo__btn-reject {
  background-color: $color-red;
  color: $color-white;

}

.quo__btn-counter {
  background-color: $color-accent;
  color: $color-black;
}

.quo__btn-delivered {
  min-width: 70%;
  background-color: $color-accent;
  color: $color-black;
}


.quo__btn-accept {
  background-color: $color-green;
  color: $color-white;
}

.quo__stats {
  display: flex;
  // justify-content: space-around;

  .quo__stat {
    padding-top: 5px;
    margin-right: 40px;
    height: 55px;
  }
}

.quo__summary {
  border-right: 1px $color-light-gray solid;
  margin-bottom: $elementXMargin;

  @media screen and (max-width: 576px) {
    padding-bottom: 30px;
    border-bottom: 1px $color-light-gray solid;
    border-right: 0;
  }

  .card__title {
    margin-bottom: 15px;
  }

  .quo__stat-number {
    color: $color-blue;
    font-weight: $font-xbold;
    font-size: 26px;
    line-height: 30px;
  }

  .quo__stat-title {
    color: $color-additional;
    font-size: 12px;
  }

}
