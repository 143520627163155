.card {
  width: 100%;
  padding-bottom: 30px;
  // height: 100%;
  border: none;
  background-color: transparent;
  border-radius: 15px;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 15px;
  box-shadow: -8px 8px 0px $color-black;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  margin-bottom: $elementXMargin;
  text-transform: uppercase;
  position: relative;
  font-weight: $font-xbold;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.share__card{
  p{
    margin-bottom: 15px;
  }
  .copy_link{
    align-items: center;
    margin-top: 15px;


    p{
      margin: 0;
    }
    .btn{
      margin-bottom: 0;
      margin-top: 10px;
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (min-width: 1200px) {
      display: flex;
      .btn{
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }
}

.saved__card{
  margin-bottom: $elementMargin;
  .las{
    margin-right: 5px;
  }
  .collapse__title{
    border: 1px $color-black solid;
    padding: 8px 15px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.quo__card{
  width: auto;
  padding-bottom: 0;

  .card-body{
    // background-color: $color-dark-gray;
    background: linear-gradient(270deg, $color-accent 0, $color-blue 100%);
    box-shadow: -8px 0 -1px $color-black;
    border-radius: 15px;
  }

  .card__title {
    text-transform: none;
    margin-bottom: 15px;
    
    h5{
      font-size: 14px;
      line-height: 1.5;
      color: $color-white;
    }
    
    .quo__card__user{
      color: $color-accent;
      text-transform: uppercase;
    }
  }
  
  .btn-toolbar{
    width: 100%;
  }

  .btn-primary{
    color: $color-white;
    background-color: $color-black;
    margin: auto;

    &:before {
      background-color: $color-accent;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-accent;
      background-color: $color-accent;
      color: $color-black;
    }
  }

  .icon-quo{
    width: 18px;
    height: 18px;
    overflow: hidden;
    background: url(#{$assetPath}/6adee41e-b113-4fb8-ac27-4b2583b3d00c.svg) center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
}

.not__quo__card{
  max-height: 500px;

  .card-body{
    background-color: $color-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: none;
    padding: 10px;

    .alert{
      border: 0;
    }
    .alert__content{
      padding: 0;
    }
  }
}

.quo__checkout__card{

  .card__title {
    text-transform: none;
    margin-bottom: 15px;

    h5{
      font-size: 18px;
    }

    .quo__card__user{
      color: $color-blue;
      text-transform: uppercase;
    }
  }

  .quo__checkout__info{
    th{
      padding-bottom: $elementMargin;
      padding-right: 10px;
    }
    td{
      padding-bottom: $elementMargin;
    }
  }
}




.manage__stripe{
  .btn-toolbar{
    margin-bottom: 30px;
  }
}

.balance__total{
  margin-right: 30%;
  text-align: left;

  .quo_balance-stat{
    font-size: 26px;
  }
}
.balance__calculate{
  
  .quo_balance-title,
  .quo_balance-stat{
    display: inline;
    margin-right: 5px;
  }

  .quo_balance-stat{
    font-size: 16px;
  }
}

.profile__card-center{
  margin-left: auto;
  margin-right: auto;
}

.steps__card__list{
  margin: auto;
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    width: 70%;
  }
}

.steps__card{
  .la-check-circle{
    display: none;
    font-size: 62px;
  }
  &.completed{
    .card-body{
      background-color: $color-green;
      color: $color-white;

      h5, p{
        color: $color-white;
      }
    }
    .la-check-circle{
      display: block;
      color: $color-accent;
    }
    .btn{
      display: none;
    }
    .icon-quo{
      background-image: url(#{$frontendPath}/img/icon-quo-white.svg);
    }
  }
  .card-title{
    font-size: 18px;
    line-height: 24px;
  }
  .step__icon{
    text-align: center;
    font-size: 70px;
    .icon-quo{
      width: 70px;
      height: 70px;
    }
  }
  .step__button{
    text-align: right;
    justify-content: center;
    align-items: center;
    display: flex;
    .btn{
      margin-bottom: 0;
      text-align: center;
      padding: 5px 15px;
    }
  }

  @media screen and (max-width: 768px) {
    .step__button{
      .btn{
        padding: 2px 5px;
      }
    }
  }
}