.account_setting {

  &.container {
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (max-width: 992px) {
      padding-top: $siteTopMobileMargin;
    }

    @media screen and (max-width: 768px) {
      padding: 0;

      .account_setting_menu {
        padding-top: $siteTopMobileMargin;
      }
    }
  }
}

.account_setting_wrapper {
  max-width: 1080px;
  margin: auto;
  display: flex;
  overflow: hidden;
}

.account_setting_menu {
  width: 300px;

  .nav-link {
    height: 36px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 11px 20px;
    overflow: hidden;
    background: transparent;
    border: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background: $color-blue;
      opacity: 0;
      transition: all 0.3s;
    }

    &:hover,
    &.active {
      text-decoration: none;

      @include themify($themes) {
        background-color: themed('colorHover');
      }

      &:before {
        opacity: 1;
      }
    }
  }

}

.account_setting_button--menu {
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 16px;
  text-align: left;
  padding: 0;
  display: none;
  color: $color-dark-gray;
}


.account_setting_card {
  transition: all 0.3s;

  .form-control {
    font-size: 12px;

    &.tag-input {
      height: auto;

      input {
        width: 100%;
        font-family: inherit;
        margin: 0;
      }
    }
  }

  textarea {
    min-height: 100px;
  }
}

@media screen and (max-width: 768px) {

  .account_setting_menu {
    width: 100%;
    margin: 0;
    padding: 0;

    .nav-link {
      height: auto;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .sidebar__link-title {
      font-size: 16px;
    }
  }

  .account_setting_button--menu {
    display: inline-block;
  }

  .account_setting_card {
    margin-right: -100%;
    border: 0;
    z-index: 1;
    padding-bottom: 0;
    min-height: calc(100vh);


    .react-tagsinput-input {
      font-size: 12px;
      padding: 0;
      width: 100%;
      color: inherit;
    }

    .react-tagsinput-tag {
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 12px;
    }

    .card-body {
      box-shadow: none;
      border-radius: 0;
    }

    &.open {
      margin-left: -100%;
    }

  }
}

.change_pw-form {
  margin-bottom: 30px;
}

.account_setting_notification_item{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 16px;
}