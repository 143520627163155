.profile__container {

  &.container {
    width: 70%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      // margin-top: -60px;
      margin-bottom: -30px;
    }
  }

  &.profile__public {
    padding-top: 60px;
    max-width: 1260px;
  }

  .full-width {
    width: 100%;
  }

}

.profile__information {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  background-size: cover;
  background-position: center top;
  // margin-top: 40px;


  .profile__col_fix {
    position: static;
  }

  .profile__btn-toolbar {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    width: auto;


    .btn {
      margin-bottom: 0;
      border: 0;
      margin-left: 5px;
    }
  }

  .profile__mid-toolbar {
    margin-top: 0;
    margin-bottom: 15px;
    gap: 15px;

    .btn {
      margin-bottom: 0;
      margin-left: inherit;
      margin-right: inherit;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 40px;

    .btn-toolbar {
      top: -45px;
    }
  }

}

.profile__card {
  background-color: $color-white;
  padding: 25px;
  border-radius: 15px 15px 0 0;
  box-shadow: -8px 8px 0px $color-black;


  .profile__name {
    font-size: 24px;
    background-color: $color-accent;
    border-radius: 3px;
    padding: 2px 8px;
    margin-top: 20px;
  }

  .profile__top {
    text-align: center;
    position: absolute;
    bottom: -15px;
    width: 100%;
    z-index: 1;
  }

  .profile__data {

    @media screen and (max-width: 360px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
    }
  }

  .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }

  .tab-content {
    padding: 0 30px 40px 30px;
  }

  &--calendar {

    .rbc-toolbar {
      -webkit-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
  }

  .btn-bookmark {
    color: $color-white;
    border-color: $color-blue;
    background-color: $color-blue;

    &::before {
      background-color: $color-blue-hover;
    }

    &.bookmarked {
      color: $color-blue;
      background-color: transparent;
    }

    &:hover {
      border-color: $color-blue-hover;
      color: $color-white;
    }
  }

  .btn-get_verified {
    font-size: 12px;
    padding: 0 10px;
  }

  .quo__card {
    .btn-toolbar {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;

    .profile__avatar {
      max-width: 250px;
      min-height: 300px;
      width: 75%;
      padding-top: 30px;
    }

    .profile__data {
      // background: linear-gradient(0deg, $color-dark-gray 70%, rgba(255,255,255,0) 100%);
      color: $color-accent;
      padding-left: 15px;
      padding-right: 15px;
    }

  }
}


.avatar-content {
  width: 200px;
  height: 200px;
}


.profile__stats {
  display: flex;
  justify-content: space-around;
}

.profile__stat {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.profile__stat-number {
  color: $color-blue;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.profile__stat-title {
  margin: 0;
  color: $color-additional;
  font-size: 12px;
  line-height: 14px;
}

.profile__top {

  .btn-bookmark {
    display: none;
  }

  .btn {
    padding: 0 6px;
    margin: 0;
    ;
  }

  .dropdown-menu {
    background-color: acolor-black;

    .dropdown-item {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .dropdown-item,
    a {
      color: $color-white;
      font-size: 14px;

      &:hover {
        color: $color-accent;
        background-color: inherit;
      }
    }
  }

  .icon {
    padding: 5px;
    color: $color-black;
    font-size: 16px;
    border: 0;
    background: transparent;
    cursor: pointer;

    &:hover,
    &:active,
    &.active {
      background: transparent;
      color: $color-blue;
    }
  }
}


.profile__avatar {
  max-width: 340px;
  min-height: 100px;
  text-align: center;
  position: relative;
  width: 100%;
  margin: auto;
  margin-bottom: 30px;

  img {
    border-radius: 15px;
    border: 4px $color-accent solid;
  }

  .avatar-placeholder {
    width: 150px;
    height: 150px;
    margin: auto;
  }

}

.profile__name {
  font-weight: $font-xbold;
  line-height: 1.4em;
  text-align: center;
  color: $color-black;
  display: inline-block;

  .las {
    color: $color-blue;
  }
}

.profile__location {
  margin-bottom: $elementMargin;
  color: $color-gray;
  font-size: 90%;
  text-align: center;
}

.profile__work {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0;
  opacity: 0.6;
  line-height: 18px;
}

.profile__description {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.6;
  overflow-wrap: break-word;
}

.profile__tags {
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: 15px;

  li {
    padding-left: 0;
    margin-right: 10px;
    display: inline-flex;
    color: $color-additional;
  }

  a {
    color: $color-additional;
    transition: all 0.3s;

    &:hover {
      color: $color-blue;
    }
  }
}


.profile__buttons {
  margin-top: 15px;
  display: flex;

  .btn {
    padding: 8px 15px;

    i {
      margin-right: 5px;
    }
  }

  .quo__btn {
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-black;
    font-weight: $font-bold;

    .icon-quo {
      overflow: hidden;
      background: url(#{$assetPath}/6adee41e-b113-4fb8-ac27-4b2583b3d00c.svg) center;
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
    }
  }
}


.profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;

  &:last-child {
    border: none;
  }

  img {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    width: auto;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.profile__activity-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 64px;
  margin-right: 20px;

  img {
    height: 64px;
    width: 64px;
    margin: 0;
  }
}

.profile__activity-name {
  font-weight: 700;
  color: $color-blue;
}

.profile__activity-date {
  font-weight: 400;
  color: $color-additional;
  font-size: 10px;
}

.profile__current-tasks-title {
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  span {
    color: $color-additional;
    font-size: 13px;
  }
}

.profile__current-tasks {
  position: relative;
  padding-bottom: 40px;
}

.profile__current-task {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 0 20px;
  padding: 10px 0 10px 0;
  padding-right: 0;
  padding-left: 0;
  text-align: left;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  &:last-of-type {
    border-bottom: none;
  }

  &--disabled {
    cursor: default;
    opacity: 0.7;
  }

  label {
    margin-right: 35px;
  }

  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: $color-white;
    margin-left: auto;
    margin-right: 5px;

    &.badge-info {
      background-color: $color-accent;
    }

    &.badge-error {
      background-color: $color-red;
    }
  }
}

.profile__current-task-checkbox {

  &--disabled {
    cursor: default;
  }

  left: 0px;
}

.profile__see-all-tasks {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.profile__rating {
  position: absolute;
  left: 100%;
  bottom: -5px;
  margin-left: -45px;
  text-align: right;
  color: $color-black;
  text-wrap: nowrap;

  &.exist {
    display: block;
  }

  .rate {
    font-weight: $font-xbold;
  }

  .profile__rating-mask {
    // width: 100%;
    height: 100%;
    background: url(#{$frontendPath}/img/rating-stars.svg) left center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 2;
  }

  .profile__rating-bar {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
}

.profile__sns {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: center;

  li {
    margin: 0 5px;
  }
}

.bookmarked__profile__card {
  text-align: center;
  color: $color-white;

  .card-body {
    background-size: cover;
    padding: 0;
    overflow: hidden;
  }
}

.bookmarked__profiles {
  padding-top: 30px;

  @media screen and (max-width: 992px) {
    padding-top: $siteTopMobileMargin;
  }
}

.bookmarked__profile__wrapper {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    display: block;

  }
}

.bookmarked__profile__item {
  min-width: 280px;
  background-color: $color-white;
  padding: 1.25rem;
  border-radius: 15px;
  margin-bottom: 15px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  a {
    display: flex;
    gap: 15px;
  }


  .profile__avatar {
    width: auto;
    min-height: auto;
    margin: 0;

    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
    }
  }


  @media screen and (max-width: 768px) {
    width: 100%;

    .profile__avatar img {
      width: 50px;
    }
  }

  .profile__location {
    display: inline-block;
    margin-bottom: 0;
  }

  .profile__name {
    margin-right: 10px;
    font-size: 16px;
  }

  .profile__description {
    color: $color-black;
  }

  .icon-quo {
    overflow: hidden;
    background: url(#{$assetPath}/6adee41e-b113-4fb8-ac27-4b2583b3d00c.svg) center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-right: 5px;
  }

  .btn-group {
    text-align: center;
    align-items: center;
    margin: 0;
  }

  .btn {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .btn:hover {
    color: $color-black;
    border-color: $color-accent;

    &:before {
      background-color: $color-accent;
    }
  }
}

.account_setting_button-cropper {
  .editor-canvas {
    margin: auto;
    margin-bottom: 30px;
  }

  input {
    width: 200px;
    margin: auto;
    margin-bottom: 30px;
  }
}

.share__image_container {
  height: 800px;
  background-color: $color-accent;
  display: flex;

  // justify-content: center;
  // align-items: center;
  .quobal__logo {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-image: url(#{$assetPath}/76f0969e-9ab1-479e-b7a3-1447b204e67a.svg);
  }
}

.share__image_box {
  background-color: $color-blue;
  border-radius: 15px;
  padding: 30px;
  position: relative;
  margin: auto;

  .share__image_pic {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    margin: auto;
    margin-top: -60px;
    border: 2px solid $color-white;

  }

  .bold-text {
    color: $color-white;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .share__image_link {
    background-color: $color-white;
    color: $color-blue;
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    margin: 0 20px;
    font-size: 14px;
  }
}

.share__card {
  .card__title {
    text-transform: none;

    h5 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

.get__verified {
  .subhead {
    font-weight: $font-bold;

    span {
      background-color: $color-blue;
      color: $color-white;
      margin-right: 5px;
      width: 24px;
      height: 24px;
      text-align: center;
      display: inline-block;
      border-radius: 100%;
      vertical-align: baseline;
      padding-top: 3px;
    }
  }

  .steps__row {
    display: flex;
    margin-bottom: $elementMargin;
    margin-top: $elementMargin;

    .left {
      margin-right: 15px;
    }
  }

  .btn-get_verified {
    i {
      font-size: 20px;
      color: $color-blue;
      margin-left: 5px;
    }
  }
}